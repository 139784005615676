import React, {Component} from "react";
import {connect} from "react-redux";
import {handleMenuAction} from "../redux/action/action";
import {Avatar, Button, Menu, notification} from "antd";
import {indexAction} from "./indexAction";


// var [isModalVisible, setIsModalVisible] = useState(false);

class RightMenu extends Component {
    // isModalVisible, setIsModalVisible = useState(false);

    constructor(props) {
        super(props);
        this.state = {current: '1', is_login: false, current_user: null};
        this.getCurrentUser();
    }


    handleClick = e => {
        this.setState({current: e.key});
        const {dispatch} = this.props;
        dispatch(handleMenuAction(e.key));
    };

    handleLogin() {

    };

    showRegisterModal() {

    };


    getCurrentUser() {
        indexAction.getCurrentUser().then(
            (res) => {
                if (res.data.data.status === 200 && res.data.data.content !== null ) {
                    this.setState({current_user: res.data.data.content})
                }
            },
            (error) => {
                notification.error("获取当前用户失败", error.message);
            }
        )
    };


    logout = e => {
        indexAction.logout().then(
            (res) => {
                this.setState({is_login: false, current: e.key});
                const {dispatch} = this.props;
                dispatch(handleMenuAction(e.key));
            },
            (error) => {
                notification.info("注销失败");
            });
    };


    render() {
        let isLogin = this.props.IS_LOGIN;
        let currentUser = this.state.CURRENT_USER;
        return (<>
                {isLogin ? (
                    <Menu
                        onClick={this.logout}
                        style={{
                            width: 220,
                            lineHeight: '64px',
                            display: 'inline-block',
                        }}
                        selectedKeys={[this.state.current]}
                        mode="horizontal"
                    >
                        <Menu.SubMenu
                            title={
                                <span className="submenu-title-wrapper">
                          <Avatar
                              onClick={this.showDrawer}
                              size="large"
                              icon="user"
                              src={currentUser.avatar}
                              style={{marginRight: 5}}
                          />
                                    {currentUser.userName}
                        </span>
                            }
                        >
                            <Menu.ItemGroup>
                                <Menu.Item key="logout">退出</Menu.Item>
                            </Menu.ItemGroup>
                        </Menu.SubMenu>
                    </Menu>
                ) : (
                    <div>
                        <Button
                            type="primary"
                            icon="login"
                            style={{marginRight: '15px'}}
                            onClick={this.handleLogin}
                        >
                            登 录
                        </Button>
                        <Button
                            type="danger"
                            icon="logout"
                            style={{marginRight: '15px'}}
                            onClick={this.showRegisterModal}
                        >
                            注 册
                        </Button>
                    </div>
                )}
            </>

        );
    }
}

const mapStateToProps = state => {
    return {
        CURRENT_MENU: state.current,
        IS_LOGIN: state.is_login,
        CURRENT_USER: state.user_name
    }
};
export default connect(mapStateToProps)(RightMenu)

import React, {Component} from "react";
import {connect} from "react-redux";

class Admin extends Component {
    componentDidMount() {
    }
    render() {
       return(
           <div>

           </div>
       )
    }
}
export default connect()(Admin)

import React, {Component} from "react";
import {connect} from "react-redux";
import "./index.less";
import LeftMenu from "./left";
import RightMenu from "./right";
import {Col, Row, Layout} from "antd";

const { Header } = Layout;

class HeaderBar extends Component {

    render() {
        // const menuItem = menu;
        return (
            <Header
                className="header "
                style={{
                    position: 'fixed',
                    zIndex: 1,
                    top: 0,
                    width: '100%',
                    minWidth: '1200px',
                    height: '66px',
                    float: 'left',
                    backgroundColor: 'white',
                    borderBottom: '1px solid #eee',
                }}
            >
                <Row className="container">
                    <Col style={{width: '900px', float: 'left'}}>

                        <LeftMenu className={"left-menu"}/>
                    </Col>
                    <Col
                        style={{textAlign: 'right', width: '300px', float: 'left'}}>
                        <RightMenu className={"right-menu"}/>
                    </Col>
                </Row>
            </Header>
        );
    }
}

export default connect()(HeaderBar)
